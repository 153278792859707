import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { Drawer, duration } from "@mui/material";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function App() {
  const [dim, setDim] = useState([0, 0]);
  const [scale, setScale] = useState(
    JSON.parse(localStorage.getItem("scale")) || [6, 6]
  );
  const [open, setOpen] = useState(false);
  const [vol, setVol] = useState(parseInt(localStorage.getItem("vol")) || 50);
  const [playing, setPlaying] = useState(false);
  const [currTime, setCurrTime] = useState(0);
  const [currDuration, setCurrDuration] = useState(0);

  useEffect(() => {
    let video = document.getElementById("video");
    if (video != null) {
      video.volume = vol / 100;
    }
    localStorage.setItem("vol", vol);
  }, [vol]);

  useEffect(() => {
    localStorage.setItem("scale", JSON.stringify(scale));
    let scaler = (e) => {
      if (e.keyCode == 98) {
        if (scale[0] - 1 < 1) {
          setScale([1, scale[1]]);
        } else {
          setScale([scale[0] - 1, scale[1]]);
        }
      } else if (e.keyCode == 97) {
        setScale([scale[0] + 1, scale[1]]);
      } else if (e.keyCode == 101) {
        if (scale[1] - 1 < 1) {
          setScale([scale[0], 1]);
        } else {
          setScale([scale[0], scale[1] - 1]);
        }
      } else if (e.keyCode == 100) {
        setScale([scale[0], scale[1] + 1]);
      }
    };
    window.addEventListener("keydown", scaler);
    return () => {
      window.removeEventListener("keydown", scaler);
    };
  }, [scale]);

  useEffect(() => {
    let video = document.getElementById("video");
    let metadata = () => {
      setDim([
        Math.floor(video.videoHeight / scale[0]),
        Math.floor(video.videoWidth / scale[1]),
      ]);
    };
    if (video.videoHeight != null) {
      metadata();
    }
    let durationChange = (e) => {
      console.log(e.target.duration);
      setCurrDuration(e.target.duration);
    };
    let timeUpdate = (e) => {
      setCurrTime(e.target.currentTime);
    };
    video.addEventListener("loadedmetadata", metadata);
    video.addEventListener("durationchange", durationChange);
    video.addEventListener("timeupdate", timeUpdate);
    return () => {
      video.removeEventListener("loadedmetadata", metadata);
      video.removeEventListener("durationchange", durationChange);
      video.removeEventListener("timeupdate", timeUpdate);
    };
  }, [scale]);

  useEffect(() => {
    let animationId = null;
    let canceled = false;
    let string =
      "$@B%8&WM#*oahkbdpqwmZO0QLCJUYXzcvunxrjft/\\|()1{}[]?-_+~<>i!lI;:,\"^`'.  ";
    string = string.split("").reverse().join("");
    //let string ="$@B%8&WM#*oahkbdpqwmZO0QLCJUYXzcvunxrjft/\\|()1{}[]?-_+~<>i!lI;:,\"^`'.            ";
    let chars = string.split("");
    let video = document.getElementById("video");
    const vw = dim[1];
    const vh = dim[0];

    let canvas = document.getElementById("canvas");
    let div = document.getElementById("div");
    let ctx = canvas.getContext("2d", {
      willReadFrequently: true,
      desynchronized: true,
    });
    let forceDraw = () => {
      if (!video.paused) return;
      ctx.drawImage(video, 0, 0, vw, vh);
      var frame = ctx.getImageData(0, 0, vw, vh);
      let lines = "";
      for (var i = 0; i < vh; i++) {
        let line = "";
        for (var l = 0; l < vw; l++) {
          let currIndex = (i * vw + l) * 4;
          let r = frame.data[currIndex];
          let g = frame.data[currIndex + 1];
          let b = frame.data[currIndex + 2];
          let avg = Math.round((r + g + b) / 3);
          let charIndex = Math.floor(
            (-(chars.length - 1) / 255) * avg + (chars.length - 1)
          );
          let char = chars[charIndex];
          line = line + char;
        }
        line = line + "\n";
        lines = lines + line;
      }
      lines.replace(/ /gm, "&nbsp;");
      div.innerText = lines;
    };
    let keydown = (e) => {
      if (e.keyCode == 32) {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      } else if (e.keyCode == 39) {
        if (video.currentTime + 10 > video.duration) {
          video.currentTime = video.duration;
        } else {
          video.currentTime += 10;
        }
      } else if (e.keyCode == 37) {
        if (video.currentTime - 10 < 0) {
          video.currentTime = 0;
        } else {
          video.currentTime -= 10;
        }
      } else if (e.keyCode == 96) {
        video.currentTime = 0;
      }
    };
    let resize = () => {
      let rvw = window.innerWidth - 5;
      let rvh = window.innerHeight - 5;
      div.style.lineHeight = rvh / vh + "px";
      div.style.fontSize = rvh / vh + "px";
      let text = "";
      for (let i = 0; i < vw; i++) {
        text = text + "#";
      }
      div.innerText = text;
      div.style.letterSpacing = "unset";
      div.style.letterSpacing = (rvw - div.offsetWidth) / vw + "px";
      let ctx = canvas.getContext("2d", {
        willReadFrequently: true,
        desynchronized: true,
      });
      div.innerText = "";
      forceDraw();
    };

    let play = () => {
      setPlaying(true);
      //video.volume = (parseInt(localStorage.getItem("volume")) || 50) / 100;
      function draw() {
        animationId = requestAnimationFrame(draw);

        if (video.paused || video.ended || canceled) {
          cancelAnimationFrame(animationId);
          if (!canceled) setPlaying(false);
          return;
        }

        //ctx.clearRect(0, 0, vw, vh);
        ctx.drawImage(video, 0, 0, vw, vh);
        var frame = ctx.getImageData(0, 0, vw, vh);
        let lines = "";
        for (var i = 0; i < vh; i++) {
          let line = "";
          for (var l = 0; l < vw; l++) {
            let currIndex = (i * vw + l) * 4;
            let r = frame.data[currIndex];
            let g = frame.data[currIndex + 1];
            let b = frame.data[currIndex + 2];
            let avg = Math.round((r + g + b) / 3);
            let charIndex = Math.floor(
              (-(chars.length - 1) / 255) * avg + (chars.length - 1)
            );
            let char = chars[charIndex];
            line = line + char;
          }
          line = line + "\n";
          lines = lines + line;
        }
        lines.replace(/ /gm, "&nbsp;");
        div.innerText = lines;
      }
      draw();
    };
    if (dim[0] != 0 && dim[1] != 0) {
      resize();
      video.addEventListener("timeupdate", forceDraw);

      window.addEventListener("resize", resize);
      document.addEventListener("keydown", keydown);
      if (!video.paused) {
        resize();
        play();
      }
      video.addEventListener("play", play);
    }
    return () => {
      if (dim != [0, 0]) {
        if (animationId != null) {
          canceled = true;
        }
        window.removeEventListener("resize", resize);
        video.removeEventListener("play", play);
        video.removeEventListener("timeupdate", forceDraw);
        document.removeEventListener("keydown", keydown);
      }
    };
  }, [dim]);

  const resume = () => {
    let video = document.getElementById("video");
    video.play().then(() => {
      video.volume = vol / 100;
      setPlaying(true);
    });
  };

  const pause = () => {
    let video = document.getElementById("video");
    video.pause();
    setPlaying(false);
  };

  const setTime = (val) => {
    let video = document.getElementById("video");
    video.currentTime = val;
    setCurrTime(val);
  };

  let seconds = Math.floor(currTime) % 60;
  let minutes = (Math.floor(currTime) - seconds) / 60;

  return (
    <>
      <video id="video" crossOrigin="anonymous" loop autoPlay controls={true}>
        <source src="fish.mp4" type="video/mp4" />
      </video>
      <canvas
        id="canvas"
        height={dim[0]}
        width={dim[1]}
        style={{ display: "none" }}
      ></canvas>
      <div id="div"></div>
      <Drawer
        open={open}
        anchor="right"
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="container">
          <VolumeDown />
          <Slider
            aria-label="Volume"
            value={vol}
            onChange={(e, val) => setVol(val)}
            min={0}
            max={100}
            valueLabelDisplay="auto"
          />
          <VolumeUp className="trail" />
        </div>
        <div
          className={"container" + (playing ? " active" : "")}
          onClick={playing ? () => pause() : () => resume()}
        >
          {playing ? (
            <>
              <PauseIcon /> Pause
            </>
          ) : (
            <>
              <PlayArrowIcon /> Play
            </>
          )}
        </div>
        <div className="container">
          <div className="timer">
            {minutes > 9 ? "" : "0"}
            {minutes}:{seconds > 9 ? "" : "0"}
            {seconds}
          </div>
          <Slider
            aria-label="Volume"
            value={isNaN(currTime) ? 0 : currTime}
            onChange={(e, val) => setTime(val)}
            min={0}
            max={isNaN(currDuration) ? 0 : currDuration}
          />
        </div>
        <div className="container">
          <RemoveIcon />
          <Slider
            aria-label="Scale1"
            value={scale[0]}
            onChange={(e, val) => setScale([val, scale[1]])}
            min={1}
            max={15}
            step={1}
            valueLabelDisplay="auto"
          />
          <AddIcon className="trail" />
        </div>
        <div className="container">
          <RemoveIcon />
          <Slider
            aria-label="Scale2"
            value={scale[1]}
            onChange={(e, val) => setScale([scale[0], val])}
            min={1}
            max={15}
            step={1}
            valueLabelDisplay="auto"
          />
          <AddIcon className="trail" />
        </div>
      </Drawer>
      <div
        id="menu"
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className="menu-container">
          {open ? <MenuOpenIcon /> : <MenuIcon />}
        </div>
      </div>
    </>
  );
}

export default App;
